<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="d-none d-md-block">
      <hero-component
        btn-text="Get started on your condo policy"
        btn-color="#00A1B7"
        btn-text-color="#FFFFFF"
        background-url="/img/condo/condo_desktop.jpg"
        btn-link="/condo/info"
      >
        We've got your<br>condo<br>covered.
      </hero-component>
      <div class="section2 pb-16">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your condo and its contents with Blanket.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-center mt-16 mb-n6">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-cash-multiple
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-application-cog-outline
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-lifebuoy
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="10"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Cover what your homeowners association’s insurance doesn’t.
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Customization for your needs. Flexibility for your budget.
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Support and policy access when you need it.
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex flex-column justify-center s2-desc">
              Your homeowners association’s policy usually covers shared spaces, but not your condo's interior space, your personal belongings or personal liability.. Our distinct condo policies are designed to protect your space and everything in it from the unexpected.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              You can add additional coverage for the things that are most important to you and choose a deductible that you can afford.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Our technology and customer support is here to file a claim, ask a question, or make changes to your policy.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/condo/info"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your property (and more), covered.
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  All Blanket Condo Insurance policies cover the following:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in condoCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Add on coverage for your unique condo situation:
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in extraCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  <v-btn
                    class="sec4-sub"
                    text
                    @click="scrollToFaq('#faq')"
                  >
                    Learn more about Blanket’s condo insurance
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get condo coverage in less time than it takes to order take-out
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/condo/info"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your condo policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          src="/img/renters-2022/Mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <div
        id="#faq"
        class="faq-sec-desk pt-16 pb-16"
      >
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-desk"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <div v-if="tabletDevice">
      <hero-component
        btn-text="Get started on your condo policy"
        btn-color="#00A1B7"
        btn-text-color="#FFFFFF"
        background-url="/img/condo/condo_tablet.jpg"
        btn-link="/condo/info"
      >
        We've got your<br>condo<br>covered.
      </hero-component>
      <div class="section2-tablet pb-16 px-2">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your condo and its contents with Blanket.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex align-start justify-center mt-16 mb-n6">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-cash-multiple
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-application-cog-outline
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-lifebuoy
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="12"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Cover what your homeowners association’s insurance doesn’t.
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                <!-- 10-Day Free Look <br> Period -->
                Customization for your needs. Flexibility for your budget.
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Support and policy access when you need it.
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center s2-desc">
              Your homeowners association’s policy usually covers shared spaces, but not your condo's interior space, your personal belongings or personal liability.. Our distinct condo policies are designed to protect your space and everything in it from the unexpected.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              You can add additional coverage for the things that are most important to you and choose a deductible that you can afford.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Our technology and customer support is here to file a claim, ask a question, or make changes to your policy.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/condo/info"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your property <br> (and more), covered.
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  All Blanket Condo Insurance policies cover the following:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in condoCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Add on coverage for your unique condo situation:
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in extraCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-start">
          <v-col
            cols="12"
            lg="12"
            xl="8"
            class="pt-10 px-0"
          >
            <v-btn
              class="s4-sub-mobile"
              text
              @click="scrollToFaq('faq-tablet')"
            >
              Learn more about Blanket’s condo insurance
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get condo coverage in less time than it takes to order take-out.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/condo/info"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your condo policy today</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        class="faq-sec-desk pt-16 pb-16"
      >
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          id="faq-tablet"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-tablet"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-if="mobileDevice">
      <div
        class="section1-mobile"
      ></div>
      <div class="section2-mobile">
        <div class="section2-title-mobile">
          <v-row class="mb-5">
            <v-col class="pt-5">
              We've got your<br>condo<br>covered.
            </v-col>
          </v-row>
          <v-btn
            x-large
            class="mint mx-auto py-8"
            to="/condo/info"
          >
            <span class="section2-btn-txt-mobile pt-1">get started on <br>your condo policy</span>
          </v-btn>
        </div>
      </div>
      <div class="section3-mobile">
        <v-row class="pt-10">
          <v-col class="section3-title-mobile">
            Protect your condo and its contents with Blanket.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-cash-multiple
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Cover what your homeowners association’s insurance doesn’t.
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Your homeowners association’s policy usually covers shared spaces, but not your condo's interior space, your personal belongings or personal liability.. Our distinct condo policies are designed to protect your space and everything in it from the unexpected.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              large
              class="primary--text"
            >
              mdi-application-cog-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            <!-- 10-Day Free Look Period -->
            Customization for your needs. Flexibility for your budget.
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            You can add additional coverage for the things that are most important to you and choose a deductible that you can afford.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-lifebuoy
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Support and policy access when you need it.
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Our technology and customer support is here to file a claim, ask a question, or make changes to your policy.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="d-flex justify-center">
            <v-btn
              class="mint darkgrey--text text-h4 font-weight-bold mr-0"
              to="/condo/info"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4-mobile pb-10">
        <v-row class="pt-8">
          <v-col class="s4-title-mobile">
            Your property (and more), covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            All Blanket Condo Insurance policies cover the following:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in condoCoverages"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            Add on coverage for your unique condo situation:
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            v-for="item in extraCoverages"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            lg="12"
            xl="8"
            class="pt-10 text-center"
          >
            <div>
              <v-btn
                class="text-center primary white--text font-weight-bold text-h5 pa-8"
                x-large
                @click="scrollToFaq('faq-mobile')"
              >
                Learn more about <br> Blanket’s condo insurance
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="s5-mobile">
        <v-row class="pt-10">
          <v-col class="s5-title-mobile">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s5-sub-mobile">
            Get condo coverage in less time than it takes to order take-out.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center pt-6">
            <v-btn
              x-large
              class="mint mx-auto py-8"
              to="/condo/info"
            >
              <span class="section2-btn-txt-mobile pt-1">Get your condo policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          class="phone-mobile"
          src="/img/phone-mobile.png"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          id="faq-mobile"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-mobile"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'
  export default {
    name: 'Dashboard',
    components: { HeroComponent },

    data () {
      return {
        covid19Faq: false,
        covFaq: false,
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
        condoCoverages: [
          'Personal Property', 'Dwelling', 'Loss of Use', 'Personal Liability', 'Medical Payments', 'Loss Assessment',
        ],
        extraCoverages: [
          'Animal Liability (depends on your residence location)', 'Identity Theft Protection', 'Personal Injury Coverage', 'Special Personal Property Coverage', 'Water Backup and Earthquake (depends on your residence location)'
        ],
        mainFaqList: [
          {
            status: false,
            title: 'FAQs',
            list: [
              {
                ques: 'Does condo insurance cover personal injury? ',
                ans: 'For an additional premium, The Personal Injury endorsement can be added to your policy. Personal Injury extends the Liability portion of your policy to include mental or psychological injury or damages resulting from things like false arrest, slander or libel.'
              },
              {
                ques: 'What does my Condo Policy cover as opposed to my Homeowner’s Association policy?',
                ans: 'Condo insurance covers the inside of your condo while the external areas like the pool, hot tub, elevators and the building itself is covered by the Homeowner’s Association insurance policy.'
              },
              {
                ques: 'Does my condo insurance cover theft? ',
                ans: 'Yes, theft coverage is one of the perils your condo policy insures against. However, please note that if your condo is seasonal or rented out for more than 180 days in a calendar year then theft coverage is limited to burglary coverage only.'
              },
              {
                ques: 'Is condo insurance cheaper than home insurance?',
                ans: 'Yes. Condo insurance protects the interior of the unit as opposed to the external structure. This accounts for the typically significant cost difference between a Condo Policy and a Homeowners policy, since the latter protects the entire structure.'
              },
              {
                ques: 'Do I need loss assessment Coverage? ',
                ans: 'Your condo policy comes with the option to add Loss Assessment coverage for an additional premium. Loss Assessment Coverage protects you if your condo association surpasses the limits of its master policy in the event of a catastrophe event such as a hailstorm or windstorm. In this instance, each unit member would be required to contribute funds to make up the difference between the coverage limits that the HOA policy has, and the actual cost to repair/replace. If you have purchased loss assessment coverage, this amount would be covered in part or wholly by your insurance company protecting your savings from this out-of-pocket expense.'
              },
              {
                ques: 'What\'s an "all-in" condo master policy? ',
                ans: 'Your condo association has a master policy that covers the exterior of your complex and may sometimes include coverage for the interior of your unit as well. An "all-in" condo master policy means that fixtures in your condo, such as appliances, wiring, plumbing and carpets are covered under the master policy. It does not cover personal property.'
              },
              {
                ques: 'What\'s a "bare walls-in and wall studs in" condo master policy?',
                ans: 'Your condo association has a master policy that covers the exterior of your complex and the bare structure of your unity. In addition to not covering your personal belongings, it does not provide coverage for your condo\'s interior fixed contents, such as bathroom and kitchen fixtures or countertops.'
              },
            ],
          },
        ],
      }
    },
    computed: {
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      largeDevice () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
    },
    created () {
      this.$gtag.pageview('Condo USA')
      this.currentuser = this.$store.getters.getuser
      this.$store.dispatch('getPropertyTypes')
      this.$store.dispatch('clearError')
    },

    methods: {
      scrollToFaq (id) {
        // scroll to faq section
        const faq = document.getElementById(id)
        faq.scrollIntoView({ behavior: 'smooth' })
      },
      toggleFaq (index) {
        this.mainFaqList[index].status = !this.mainFaqList[index].status
      },
      toggleCovid19Faq () {
        this.covid19Faq = !this.covid19Faq
      },
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.s2-icon {
  font-size: 50px !important;
}

.apply-btn {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 22.6667px;
line-height: 125%;
/* or 28px */

text-align: center;
letter-spacing: 0.944445px;
text-transform: uppercase;

/* Color Palette/Dark Grey */

color: #292F36;
}

.s2-desc {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}
  .s2-subhead {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
  }
  .s2-sub {
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
  }

  .cta-btn {
    position: absolute;
    top: 78%;
    left: 9%;
  }

  .cta-txt {
    font-weight: 700;
    font-size: 17.9859px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.749412px;
    text-transform: uppercase;
    color: #292F36;
    padding: 0 !important;
  }
  .sora {
position: absolute;
top: 45%;
left: 9%;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
  }

  .section2-header {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
  }

  .s2-title {
    padding-top: 79px;
  }
.title-text-mobile {
    font-weight: 500;
    color: #616161;
  }
.cover{
  font-size: 55px;
  color: #757575;
}
.read-more{
  font-size: 18px;
  color: #00A1B7;
}
.quote-btn{
  font-weight: bold
}
.section1-desktop{
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/condo/condo_desktop.jpg");
  background-size: cover;
  background-position: 0 70%;
}

.section1-xl{
  position: relative;
  width: 100vw;
  height: 900px;
  background: url("/img/condo/condo_desktop.jpg");
  background-size: cover;
  background-position: 0 45%;
}
.section2 {
position: relative;
/* background-color: #FFFFFF; */
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}
.spacer {
  height: 6rem;
  z-index: 2;
}

.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
}
.pet-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
z-index: 1;
background-color: white;
}
.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}
.btn-mrg1 {
  margin-left: 0;
}
.btn-mrg2 {
  margin-left: 200px;
}
.cov-grp {
  background-color: white;
}
.small {
    height: 700px;
}
.large {
    height: 900px;
}
.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.section3 {
  position: relative;
  height: 700px;
  background-color: #005F6C;
}

.review-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.review-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
/* or 38px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.review-details {
  font-family: 'Poppins';
font-style: italic;
font-weight: 300;
font-size: 21px;
line-height: 140%;
color: #FFFFFF;
}

.review-sig {
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.review-ins {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.portrait-card {
position: absolute;
width: 615px;
height: 700px;
left: 880px;
top: -30px;
background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(1, 0, 0, 0) ), url('/img/pet-review-portrait.png');
border-radius: 10px;
}

.portrait-quote {
  width: 300px;
height: 364px;

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 72px;
line-height: 100%;
/* or 72px */

letter-spacing: -0.636172px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.portrait-text-container {
  margin-top:100px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px 90px 20px 30px;
width: 100%;
height: 251px;
background: #00A1B7;
flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
z-index: 3;
border-radius: 0 0 0 10px;
}

.portrait-text {
  font-family: 'Poppins';
font-style: italic;
font-weight: 500;
font-size: 17.9695px;
line-height: 125%;
letter-spacing: -0.202611px;
color: #FFFFFF;
}

.signature-container{
  position: absolute;
  right: 10rem;
  top: 42rem;
}

.section4 {
background: #FFFFFF;
padding-left: 10%;
padding-right: 10%;
padding-bottom: 163px;
}

.sec4-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* Color Palette/Dark Grey */

color: #292F36;
}

.sec4-sub {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
}

.covg-cta-txt {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* midnight eagle green */

color: #005F6C;
}

.learn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 125%;
/* identical to box height, or 25px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;

/* gunmetal */

color: #292F36;
}

.sec5-dsk {
position: relative;
height: 438px;
background-image: url('/img/partner-background.jpg');
background-position: center;
background-size: cover;
padding-left: 10%;
padding-right: 10%;
}

.sec5-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}
@media screen and (max-width: 1275px) {
  .sec5-dsk-phone {
    left: 640px;
  }
}
@media screen and (max-width: 1175px) {
  .sec5-dsk-phone {
    display: none;
  }
}

.sec5-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}

.sec5-title {
  position: absolute;
  left: 130px;
  top: 76px;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */

letter-spacing: -0.424019px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.sec5-sub {
  position: absolute;
  left: 130px;
  top: 131px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 125%;
width: 598px;
height: 120px;
/* or 40px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.apply-btn {
  position: absolute;
  left: 130px;
  top: 289px;
}

.faq-sec-desk {
  background: #FFFFFF;
  padding-left: 10%;
  padding-right: 10%;
}

.faq-header-desk {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.faq-panel-icon {
  color: #005F6C;
}

.cov-faq-title:hover {
  cursor: pointer;
}

.poppins-300 {
  font-family: 'Poppins';
  font-weight: 300;
}
/*-----------------------------MOBILE STYLES-----------------------------*/
.section1-mobile {
  background-image: url('/img/condo/condo_mobile.jpg');
  background-position: center;
  background-size: cover;
  height: 450px;
  background-position: 0 65%;
}

.section2-mobile {
width: 100vw;
height: 260px;
background: #00A1B7;
}

.section2-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;

}

.section2-btn-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 125%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: #292F36;
}

.section3-mobile {
  padding-bottom: 54px;
  background-image: url('/img/knit-mobile.png');
  background-position: center;
  background-size: cover;
  padding-left: 10px;
  padding-right: 10px;
}

.section3-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #292F36;
}

.section3-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
}

.covg-txt-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}

.section4-mobile {
  padding-bottom: 54px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFFFFF;
}

.s4-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
text-align: left;
}

.panel-title-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 150%;
display: flex;
align-items: center;
color: #005F6C;
}

.panel-icon {
  color: #292F36;
  font-size: 20px !important;
}

::v-deep .v-expansion-panel-header--active .v-icon {
  color: #FFFFFF !important;
}

.s5-mobile {
  position: relative;
  min-height: 725px;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url('/img/partner-background-mobile.jpg');
  background-position: center;
  background-size: cover;
}

.s5-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.s5-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.phone-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: auto;
}

.faq-header-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

/*-----------------------------TABLET STYLES-----------------------------*/
.section1-tablet {
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/condo/condo_tablet.jpg");
  background-position: center;
  background-size: cover;
}

.section2-tablet {
  position: relative;
/* background-color: #FFFFFF; */

background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}

.faq-header-tablet {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.sora-tablet {
  position: absolute;
top: 45%;
left: 5%;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
}

.cta-btn-tablet {
    position: absolute;
    top: 78%;
    left: 5%;
  }

  ::v-deep .v-expansion-panel-header {
    color:#00A1B7;
  }

  ::v-deep .v-expansion-panel--active .v-expansion-panel-header {
    background:#00A1B7;
    color:#FFFFFF;
  }
</style>
